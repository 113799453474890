import { render, staticRenderFns } from "./UserDisPosition.vue?vue&type=template&id=681f8aef&scoped=true&"
import script from "./UserDisPosition.vue?vue&type=script&lang=js&"
export * from "./UserDisPosition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681f8aef",
  null
  
)

export default component.exports