<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item>
            <el-input v-model="selectForm.userName" placeholder=""></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
    
     
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="LeaveName" label="职务名称"> </el-table-column>
        <el-table-column label="职务待遇" prop="Salary"></el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="operation ? '新增' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :rules="SakaryRules"
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="职务名称:" prop="LeaveName">
              <el-input type="text" v-model="SakaryForm.LeaveName"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务待遇:" prop="Salary">
              <el-input type="text" v-model="SakaryForm.Salary"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import { GetUserDis, AddUserDis, UpdateIUserDis } from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      loading: true,
      searchVal: "",
      selectForm: {
        name: "",
      },
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      SakaryForm: {
        LeaveName: "",
        Salary: "",
      },
      SakaryRules: {
        LeaveName: [
          { required: true, message: "请输入职务名称", trigger: "blur" },
        ],
        Salary: [
          { required: true, message: "请输入职务待遇", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    //获取列表
    getData() {
      GetUserDis().then((res) => {
        this.users = res.data.response;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
    },
    handleAdd() {
      (this.SakaryForm = {
        LeaveName: "",
        Salary: "",
      }),
        (this.operation = true),
        (this.SakaryVisible = true);
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          if (this.operation) {
            //新增
            AddUserDis(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
            UpdateIUserDis(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

